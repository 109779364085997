<template>
  <div id="codes-list">
    <!-- app drawer -->
    <codes-list-add-new v-model="isAddNewCodeSidebarActive" @refetch-data="fetchCodes"></codes-list-add-new>

    <!-- list filters -->
    <v-card>
      <v-card-title> Search &amp; Filter </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->

      <v-row class="px-2 ma-0">
        <!--plan filter-->
        <v-col cols="12" sm="4">
          <v-select
            v-model="planFilter"
            placeholder="Select Criteria"
            label="Criteria"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <!-- search -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          :placeholder="`Search By ${planFilter?planFilter:''}`"
          label="Search"
          outlined
          hide-details
          dense
          class="events-search me-3 mb-4"
        >
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click.stop="isAddNewCodeSidebarActive = !isAddNewCodeSidebarActive">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Promo Code</span>
          </v-btn>
          <!-- <v-btn color="secondary" outlined class="mb-4">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn> -->
        </div>
      </v-card-text>
      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="codesListTable"
        :options.sync="options"
        :server-items-length="totalCodesListTable"
        :loading="loading"
        @refetch-data="fetchCodes"
      >
        <!-- iD  -->
        <template #[`item.id`]="{ item }">
          <div class="d-flex">PC{{ item.id }}</div>
        </template>

        <!-- start_date  -->
        <template #[`item.start_date`]="{ item }">
          <div class="d-flex">
            {{ moment(item.start_date) }}
          </div>
        </template>

        <!-- end_date  -->
        <template #[`item.end_date`]="{ item }">
          <div class="d-flex">
            {{ moment(item.end_date) }}
          </div>
        </template>

        <!-- status  -->
        <template  #[`item.status`]="{ item }">
          <div v-if="item.status == 'Enabled'" class="d-flex">
            <v-switch
              class="mt-0"
              color="info"
              readonly
              v-model="item.status"
              @click="
                showPopUp = true
                status = item.status == 'Enabled' ? 'disable' : 'enable'
                change_item_id = item.id
              "
              hide-details
              inset
              :label="item.status"
            ></v-switch>
          </div>
          <div v-else class="d-flex">
            <v-switch
              class="mt-0"
              color="info"
              readonly
              :value="false"
              @click="
                showPopUp = true
                status = item.status == 'Enabled' ? 'disable' : 'enable'
                change_item_id = item.id
              "
              hide-details
              inset
              :label="item.status"
            ></v-switch>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-code-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiEye }}
                  </v-icon>
                  <span>View</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <base-dialog
      width="600"
      title="Are you sure"
      :showDialog.sync="showPopUp"
      @dialog_action="dialog_action"
      type="question"
    >
      <template v-slot:content>
        <p class="dialog_message">Are you sure you want to <strong> {{ status }} </strong> this promocode?</p>
      </template>
    </base-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEye,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import baseDialog from '@/views/components/baseDialog.vue'
// sidebar
import Swal from 'sweetalert2'
import CodesStoreModule from '../CodesStoreModule'
import CodesListAddNew from './CodesListAddNew.vue'
import useCodesList from './useCodesList'
import moment from 'moment'

export default {
  components: {
    CodesListAddNew,
    baseDialog,
  },
  methods: {
    moment: date => {
      return moment(date).locale('JO').format('DD MM YYYY [@] HH:mm')
    },
  },
  setup(props, { emit }) {
    const CODES_APP_STORE_MODULE_NAME = 'app-codes'
    const status = ref(false)

    // Register module
    if (!store.hasModule(CODES_APP_STORE_MODULE_NAME)) {
      store.registerModule(CODES_APP_STORE_MODULE_NAME, CodesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CODES_APP_STORE_MODULE_NAME)) store.unregisterModule(CODES_APP_STORE_MODULE_NAME)
    })
    const {
      codesListTable,
      tableColumns,
      searchQuery,
      totalCodesListTable,
      loading,
      options,
      codesTotalLocal,
      selectedRows,
      planFilter,
      fetchCodes,
    } = useCodesList()

    const isAddNewCodeSidebarActive = ref(false)
    const showPopUp = ref(false)
    const change_item_id = ref('')

    const planOptions = [{ title: 'Date', value: 'Date' },{ title: 'Title', value: 'Title' }]

    const dialog_action = () => {
      store.dispatch('app-codes/changeStatus', change_item_id.value).then(response => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500,
        })
        fetchCodes()
      })
    }

    return {
      codesListTable,
      tableColumns,
      searchQuery,
      totalCodesListTable,
      loading,
      options,
      codesTotalLocal,
      isAddNewCodeSidebarActive,
      selectedRows,
      planOptions,
      planFilter,
      fetchCodes,
      showPopUp,
      dialog_action,
      status,
      change_item_id,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEye,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.dialog_message {
  font-size: large;
}
</style>
