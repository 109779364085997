<template>
  <v-navigation-drawer
    :value="isAddNewCodeSidebarActive"
    :permanent="isAddNewCodeSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="'500'"
    app
    @input="val => $emit('update:is-add-new-item-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Promocode</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-item-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="itemData.title"
                :rules="[validators.required]"
                outlined
                dense
                maxLength="20"
                label="Promocode title"
                placeholder="Code"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>

              <v-textarea
                v-model="itemData.description_en"
                outlined
                label="Description EN"
                rows="2"
                :rules="[validators.required,validators.minLengthValidator(itemData.description_en, 5)]"
                hide-details="auto"
                placeholder="Description EN"
                maxLength="50"
                :counter="50"
                class="mb-6"
              ></v-textarea>

              <v-textarea
                v-model="itemData.description_ar"
                outlined
                label="Description AR"
                rows="2"
                :rules="[validators.required,validators.minLengthValidator(itemData.description_ar, 5)]"
                hide-details="auto"
                placeholder="Description AR"
                maxLength="50"
                minLength="5"
                :counter="50"
                class="mb-6"
              ></v-textarea>

              <v-radio-group
                class="mt-0 mb-6"
                v-model="itemData.promocode_type"
                hide-details="auto"
                :rules="[validators.required]"
              >
                <template v-slot:label>
                  <div><strong>Promocode Type</strong></div>
                </template>
                <v-radio value="Discount">
                  <template v-slot:label>
                    <div>Discount Value</div>
                  </template>
                </v-radio>
                <v-radio value="FreeDelivery">
                  <template v-slot:label>
                    <div>Free Delivery</div>
                  </template>
                </v-radio>
              </v-radio-group>

              <v-radio-group
                v-if="itemData.promocode_type == 'Discount'"
                class="CoveredServices mt-0 mb-6"
                v-model="itemData.covered_services"
                hide-details="auto"
                :rules="[validators.required]"
              >
                <template v-slot:label>
                  <div><strong>Covered Services</strong></div>
                </template>
                <v-radio value="All">
                  <template v-slot:label>
                    <div>All</div>
                  </template>
                </v-radio>
                <v-radio value="2" @click="setCategory(1)">
                  <template v-slot:label>
                    <div>Meals</div>
                  </template>
                </v-radio>

                <v-radio-group
                  v-if="itemData.covered_services == 2"
                  class="mt-0 mb-6 ml-6"
                  v-model="itemData.meals"
                  hide-details="auto"
                  :rules="[validators.required]"
                >
                  <v-radio value="AllMeals">
                    <template v-slot:label>
                      <div>All</div>
                    </template>
                  </v-radio>
                  <v-radio value="CategoryMeal">
                    <template v-slot:label>
                      <div>Choose Category</div>
                    </template>
                  </v-radio>
                </v-radio-group>

                <v-select
                  v-if="itemData.meals == 'CategoryMeal' && itemData.covered_services == 2"
                  v-model="itemData.category_id"
                  class="subcategories mb-6"
                  label="Categories"
                  placeholder="Categories"
                  :items="subCategories"
                  item-text="name_en"
                  item-value="id"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                ></v-select>

                <v-radio value="3" @click="setCategory(2)">
                  <template v-slot:label>
                    <div>Pre-order</div>
                  </template>
                </v-radio>

                <v-radio-group
                  v-if="itemData.covered_services == 3"
                  class="mt-0 mb-6 ml-6"
                  v-model="itemData.preOrder"
                  hide-details="auto"
                  :rules="[validators.required]"
                >
                  <v-radio value="AllPreorder">
                    <template v-slot:label>
                      <div>All</div>
                    </template>
                  </v-radio>
                  <v-radio value="CategoryPreorder">
                    <template v-slot:label>
                      <div>Choose Category</div>
                    </template>
                  </v-radio>
                </v-radio-group>

                <v-select
                  v-if="itemData.preOrder == 'CategoryPreorder' && itemData.covered_services == 3"
                  v-model="itemData.category_id"
                  class="subcategories mb-6"
                  label="Categories"
                  placeholder="Categories"
                  :items="subCategories"
                  item-text="name_en"
                  item-value="id"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                ></v-select>

                <v-radio value="ListOfChefs" @click="fetchKitchens()">
                  <template v-slot:label>
                    <div>Kitchen</div>
                  </template>
                </v-radio>

                <v-select
                  v-if="itemData.covered_services == 'ListOfChefs'"
                  v-model="itemData.kitchen"
                  class="subcategories mb-6"
                  label="kitchens"
                  placeholder="kitchens"
                  :items="kitchensList"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                  @change="fetchChefs(itemData.kitchen)"
                ></v-select>

                <v-radio-group
                  v-if="itemData.covered_services == 'ListOfChefs' && itemData.kitchen"
                  class="mt-0 mb-6 ml-6"
                  v-model="itemData.Chef"
                  hide-details="auto"
                  :rules="[validators.required]"
                >
                  <v-radio value="1">
                    <template v-slot:label>
                      <div>All Chefs</div>
                    </template>
                  </v-radio>
                  <v-radio value="2">
                    <template v-slot:label>
                      <div>Choose Chef</div>
                    </template>
                  </v-radio>
                </v-radio-group>

                <v-select
                  v-if="itemData.Chef == 2"
                  v-model="itemData.chefs"
                  multiple
                  class="subcategories mb-6"
                  label="Chefs"
                  placeholder="Chefs"
                  :items="chefsList"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  clearable
                  hide-details
                ></v-select>
              </v-radio-group>

              <v-text-field
                v-if="itemData.promocode_type == 'Discount'"
                v-model="itemData.offer"
                :rules="[validators.integerValidator, validators.between(itemData.offer, 1, 100)]"
                maxLength="3"
                outlined
                dense
                suffix="%"
                label="Discount Value"
                placeholder="10"
                hide-details="auto"
                class="mb-6"
                @keypress="isNumber($event)"
              ></v-text-field>

              <v-radio-group
                class="mt-0 mb-6"
                v-model="itemData.beneficiaries"
                hide-details="auto"
                :rules="[validators.required]"
              >
                <template v-slot:label>
                  <div><strong>Beneficiaries</strong></div>
                </template>
                <v-radio value="All">
                  <template v-slot:label>
                    <div>All</div>
                  </template>
                </v-radio>
                <v-radio value="FirstTimeUsers">
                  <template v-slot:label>
                    <div>First time users</div>
                  </template>
                </v-radio>

                <v-radio value="Company">
                  <template v-slot:label>
                    <div>Company</div>
                  </template>
                </v-radio>

                <v-select
                  v-if="itemData.beneficiaries == 'Company'"
                  v-model="itemData.company_id"
                  class="mb-6"
                  :rules="[validators.required]"
                  label="Company"
                  placeholder="choose from companies"
                  :items="companyOptions"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  clearable
                  hide-details="auto"
                ></v-select>
              </v-radio-group>

              <v-text-field
                v-model="itemData.minimum_purchase_value"
                :rules="[validators.decimal_integer_Validator_not_required]"
                maxLength="5"
                outlined
                dense
                label="Minimum Purchase Value"
                placeholder="10.5"
                hide-details="auto"
                class="mb-6"
                @keypress="isNumber($event)"
              ></v-text-field>

              <v-text-field
                v-model="itemData.maximum_applied_value"
                :rules="[validators.decimal_integer_Validator_not_required]"
                maxLength="5"
                outlined
                dense
                label="Maximum Applied Value"
                placeholder="10.5"
                hide-details="auto"
                class="mb-6"
                @keypress="isNumber($event)"
              ></v-text-field>

              <v-text-field
                v-model="itemData.usage_limit"
                :rules="[validators.integerValidator_not_required]"
                maxLength="5"
                outlined
                dense
                label="Usage Limit"
                placeholder="10"
                hide-details="auto"
                class="mb-6"
                @keypress="isNumber($event)"
              ></v-text-field>

              <v-text-field
                v-model="itemData.usage_limit_per_user"
                :rules="[validators.integerValidator_not_required]"
                maxLength="5"
                outlined
                dense
                label="Usage Limits per User"
                placeholder="10"
                hide-details="auto"
                class="mb-6"
                @keypress="isNumber($event)"
              ></v-text-field>

              <v-text-field
                v-model="itemData.start_date"
                :rules="[validators.required]"
                maxLength="5"
                type="datetime-local"
                outlined
                dense
                label="Start date & time"
                hide-details="auto"
                class="date-input mb-6"
                @input="itemData.end_date = ''"
                :min="moment()"
              ></v-text-field>

              <v-text-field
                v-model="itemData.end_date"
                :rules="[validators.required]"
                maxLength="5"
                type="datetime-local"
                outlined
                dense
                label="End date & time"
                hide-details="auto"
                class="date-input mb-6"
                :min="moment(itemData.start_date)"
                :append-icon="icons.mdiAlarm"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetItemData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import {
  emailValidator,
  fileRequired,
  imageValidator,
  integerValidator,
  integerValidator_not_required,
  minLengthValidator,
  required,
  between,
  decimal_integer_Validator_not_required,
} from '@core/utils/validation'
import { mdiClipboardFileOutline, mdiClose, mdiDelete, mdiPlus } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
  model: {
    prop: 'isAddNewCodeSidebarActive',
    event: 'update:is-add-new-item-sidebar-active',
  },
  props: {
    isAddNewCodeSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    moment: date => {
      if (date) {
        return moment(date).locale('JO').format('yyyy-MM-DDThh:mm:ss')
      } else {
        return moment().locale('JO').format('yyyy-MM-DDThh:mm:ss')
      }
    },
  },

  setup(props, { emit }) {
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }
    const companyOptions = ref([])
    const kitchensList = ref([])
    const chefsList = ref([])

    onMounted(() => {
      store
        .dispatch('app-codes/fetchCompanies')
        .then(response => {
          companyOptions.value = response
        })
        .catch(error => {
          console.error(error)
        })
    })

    const resetForm = () => {
      form.value.reset()
    }

    const isNumber = evt => {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }

    const fetchKitchens = () => {
      store
        .dispatch('app-codes/fetchKitchens')
        .then(response => {
          kitchensList.value = response.data.data.filteredData
        })
        .catch(error => {
          console.error(error)
        })
    }

    const fetchChefs = id => {
      store
        .dispatch('app-codes/fetchChefs', {
          options: {
            kitchen_id: id,
          },
        })
        .then(response => {
          chefsList.value = response.data.data.filteredData
          console.log('chefsList.value', chefsList.value)
        })
        .catch(error => {
          console.error(error)
        })
    }
    const blankItemData = {
      title: '',
      description_en: '',
      description_ar: '',
      covered_services: '',
      promocode_type: '',
      offer: '',
      beneficiaries: '',
      minimum_purchase_value: '',
      maximum_applied_value: '',
      usage_limit: '',
      usage_limits_per_user: '',
      status: 'Enabled',
      start_date: '',
      end_date: '',
      category_id: '',
      company_id: '',
      chefs: [],
      Chef: ''
    }

    const subCategories = ref([])

    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)))

    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankItemData))
      resetForm()
      emit('update:is-add-new-item-sidebar-active', false)
    }

    const setCategory = value => {
      subCategories.value = []

      store
        .dispatch('app-codes/fetchSubCategories', value)
        .then(response => {
          subCategories.value = response.filteredData
        })
        .catch(error => {
          console.error(error)
        })
    }

    const onSubmit = () => {
      if (valid.value) {
        let data = { ...itemData.value }

        if ((data.promocode_type = 'FreeDelivery')) data.covered_services = 'All'
        if ((data.Chef = '1')) data.covered_services = 'All'

        data.start_date = moment(data.start_date).add('1', 'minutes').format('yyyy-MM-DD HH:mm')
        data.end_date = moment(data.end_date).add('2', 'minutes').format('yyyy-MM-DD HH:mm')

        if (!data.chefs.length) delete data.chefs

        store
          .dispatch('app-codes/addCode', data)
          .then(response => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
            emit('refetch-data')
            emit('update:is-add-new-item-sidebar-active', false)
            resetItemData()
          })
          .catch(error => {
            if (error?.data?.message) {
              if (Array.isArray(error.data.message)) {
                Swal.fire({
                  icon: 'error',
                  title: `Error`,
                  text: `${Object.values(error.data.message).join('\n')}`,
                })
              } else {
                Swal.fire({
                  icon: 'error',
                  title: `Error`,
                  text: error.data.message,
                })
              }
            }
          })
      } else {
        validate()
      }
    }

    return {
      isNumber,
      chefsList,
      kitchensList,
      fetchKitchens,
      fetchChefs,
      resetItemData,
      form,
      onSubmit,
      itemData,
      setCategory,
      companyOptions,
      subCategories,
      valid,
      validate,
      // validation
      validators: {
        between,
        integerValidator,
        integerValidator_not_required,
        required,
        imageValidator,
        fileRequired,
        minLengthValidator,
        emailValidator,
        decimal_integer_Validator_not_required,
      },
      icons: {
        mdiClose,
        mdiClipboardFileOutline,
        mdiPlus,
        mdiDelete,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.CoveredServices {
  border: solid 0.2rem gray;
  border-radius: 0.4rem;
  padding: 0.8rem;
}
.subcategories {
  width: 15rem;
  margin: auto;
}

.t-datetime-picker {
  .v-tabs-slider-wrapper {
    top: 0;
  }
  .v-picker__title {
    height: 90px;
  }
  .v-time-picker-title__time * {
    font-size: 60px;
    height: 60px;
  }
  .v-picker__body {
    height: 290px;
  }
  .v-tabs-items {
    height: 380px;
  }
}

</style>
<style>
.date-input > .v-input__control > .v-input__slot > .v-text-field__slot > input::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
}
</style>